<!--
@description: 对外展示的个人中心
@author: fc
@createtime: 2021年12月11日 20:46:55 
-->
<template>
  <div class="userinfo-container">
    <div class="userinfo-back"></div>
    <div class="line-ele">
      <img class="user-avatar" :src="img" />
    </div>
    <div class="user-description">
      <h2 class="desc-name">{{ username }}</h2>
      <p class="self-introduction">
        {{ introduction }}
      </p>
    </div>
    <div class="list-container">
      <div class="list-header">
        <el-badge
          :value="events.length"
          class="event-btn"
          style="border-bottom: 1px solid #000"
          @click.native="handleIsEventChange(true)"
          >相关邀请</el-badge
        >
      </div>
      <div
        class="list-content"
        v-loading="loading"
        :element-loading-text="$t('lang.loading')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 250, 233, 0.85)"
      >
        <div class="events-container">
          <ul class="event-list">
            <li
              class="event-item"
              v-for="event of events"
              :key="event.id"
              @click.stop="initChangeTo(event)"
            >
              <div
                :class="
                  event.status || event.status === 0
                    ? `event-status ${statusClass[event.status]}`
                    : `event-status`
                "
              >
                {{ statusNameMap[event.status] }}
              </div>
              <div class="event-time">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-rili-mian"></use>
                </svg>
                <el-date-picker
                  prefix-icon="el-icon-none"
                  v-model="event.date"
                  type="datetimerange"
                  range-separator="—"
                  :start-placeholder="$t('lang.formHolder[5]')"
                  :end-placeholder="$t('lang.formHolder[6]')"
                  align="right"
                  format="yyyy-MM-dd HH:mm"
                  :disabled="handleShowBtn('disableDate', event)"
                  class="datetime"
                >
                </el-date-picker>
              </div>
              <div class="event-targets">
                <span class="event-postUser">
                  <img :src="event.postUser.avatar" class="postUser-avatar" />
                  <span class="postUser-username"
                    >{{ event.postUser.username }}
                  </span>
                </span>
                <span class="event-action">{{ $t("lang.invites") }}</span>
                <span class="event-accepter">
                  <img
                    :src="event.receiveUser.avatar"
                    class="receiveUser-avatar"
                  />
                  <span class="receiveUser-username"
                    >{{ event.receiveUser.username }}
                  </span>
                </span>
              </div>
              <div class="event-content">{{ event.content }}</div>
              <div class="event-description">{{ event.description }}</div>
              <div class="event-bounty">
                <span class="bounty">{{ event.bounty }}</span
                ><span style="color: #b7b7c2">$coop</span>
              </div>
              <address class="event-address">
                <i class="el-icon-location"></i
                ><span style="color: #b7b7c2">{{ event.address }}</span>
              </address>
              <div
                class="contact-container"
                v-show="handleShowBtn('contact', event) && event.status != 0"
              >
                <span class="contact-type-text">{{
                  $t("lang.receiverContact")
                }}</span>
                <div class="contact-item">
                  <span class="contact-type">
                    <img
                      class="type-icon"
                      :src="typeImageCache[event.receiverContact.contactType]"
                    />
                    <span class="type-name">{{
                      event.receiverContact.contactType
                    }}</span>
                  </span>
                  <el-input
                    disabled
                    class="contact-input"
                    v-model="event.receiverContact.contactDetail"
                    placeholder="Include #Code"
                  ></el-input>
                </div>
              </div>
              <div
                class="contact-container"
                v-show="handleShowBtn('contact', event)"
              >
                <span class="contact-type-text">{{
                  $t("lang.postUserContact")
                }}</span>
                <div class="contact-item">
                  <span class="contact-type">
                    <img
                      class="type-icon"
                      :src="typeImageCache[event.postUserContact.contactType]"
                    />
                    <span class="type-name">{{
                      event.postUserContact.contactType
                    }}</span>
                  </span>
                  <el-input
                    disabled
                    class="contact-input"
                    v-model="event.postUserContact.contactDetail"
                    placeholder="Include #Code"
                  ></el-input>
                </div>
              </div>
              <div class="event-comment">
                <span class="comment-more"
                  ><span @click="setModuleDialog([true, event.id])">{{
                    $t("lang.viewmore")
                  }}</span></span
                >
              </div>
            </li>
          </ul>
          <el-pagination
            class="event-pagination"
            @size-change="handleEventSizeChange"
            @current-change="handleCurrentEventChange"
            :current-page="currentEventPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="eventPageSize"
            layout="total, sizes, prev, pager, next"
            :total="eventsTotal"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  imagesCache,
  avatarDefault,
  statusClass,
  statusNameMap_zh,
  statusNameMap_en,
} from "@data/avatar.js";
import { getUserAllEvents, getUserInfo } from "@/api/public.js";
import btnShowMixin from "@/mixins/btnShowMixins.js";
import moduleMethodMixin from "@/mixins/moduleMethod.js";

export default {
  name: "user_profile_wap",
  mixins: [btnShowMixin, moduleMethodMixin],
  data() {
    return {
      events: [],
      eventPageSize: 10,
      eventsTotal: 0,
      currentEventPage: 1,
      statusClass: statusClass,
      statusNameMap: statusNameMap_zh,
      loading: false,
      isShowComment: false,
      comments: [],
      inputComment: "",
      userInfoView: {},
    };
  },
  computed: {
    username() {
      if (this.userInfoView && this.userInfoView.username) {
        return this.userInfoView.username;
      } else {
        return "username";
      }
    },
    img() {
      if (this.userInfoView && this.userInfoView.avatar) {
        if (this.userInfoView.avatar.length > 2) {
          return this.userInfoView.avatar;
        } else {
          return imagesCache[this.userInfoView.avatar];
        }
      } else {
        return avatarDefault;
      }
    },
    introduction() {
      if (this.userInfoView && this.userInfoView.introduction) {
        return this.userInfoView.introduction;
      } else {
        return ``;
      }
    },
  },
  watch: {
    "$i18n.locale"(val) {
      if (val == "zh-CN") {
        this.statusNameMap = statusNameMap_zh;
      } else {
        this.statusNameMap = statusNameMap_en;
      }
    },
  },
  async created() {
    let id = this.$route.query.id;
    let res = await getUserInfo({
      userId: id,
    });
    if (res.code == 200) {
      this.userInfoView = res.data;
    }
    this.initEvents(id);
  },
  methods: {
    async initEvents(id) {
      let events = [];
      this.loading = true;
      try {
        events = await getUserAllEvents({
          userId: id,
          page: this.currentEventPage,
          pageSize: this.eventPageSize,
          createdAt: "desc",
        });
        this.eventsTotal = events.data.length;
        this.events = events.data.map((item) => {
          this.preSetEvent(item);
          return {
            id: item.id,
            status: item.status,
            date: [
              new Date(item.startTime).getTime(),
              new Date(item.endTime).getTime(),
            ],
            postUser: item.postUser,
            postUserContact: item.postUserContact,
            receiverContact: item.receiverContact,
            receiveUser: item.receiveUser,
            bounty: item.remuneration,
            content: item.topic,
            description: item.description,
            address: item.location,
          };
        });
      } catch (error) {
        console.log(error);
        if (error.data && error.data.msg) {
          this.$message(error.data.msg);
        }
      }
      this.loading = false;
    },
    handleCurrentEventChange() {
      this.initEvents();
    },
    handleEventSizeChange() {
      this.initEvents();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";

.userinfo-container {
  height: 100%;
  margin-top: 184px;
  padding: 50px 0px;
  .userinfo-back {
    position: absolute;
    width: 100%;
    background: $color-bg;
    height: 100%;
    border-radius: 50px;
    margin-top: 54px;
    margin-left: 0px;
  }
  .line-ele {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .user-avatar {
      width: 108px;
      height: 108px;
      border: 4px solid #ffffff;
      background: $color-bg;
      border-radius: 54px;
      box-sizing: border-box;
      filter: drop-shadow(0px 16px 32px rgba(23, 18, 46, 0.16));
      box-shadow: 0px 16px 32px rgba(23, 18, 46, 0.16);
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-left: 50px;
    }
    .btn-position {
      width: 108px;
      height: 108px;
      margin-right: 50px;
      display: inline-flex;
      align-items: center;
      z-index: 1;
    }
    .edit-userinfo-btn {
      width: 150px;
      height: 40px;
      border-radius: 54px;
      box-shadow: 0px 16px 32px rgba(23, 18, 46, 0.16);
      &:hover {
        background-color: #fff;
        border-color: #fff;
      }
    }
  }
  .user-description {
    color: $color-text;
    position: absolute;
    margin-top: 40px;
    margin-left: 50px;
    .desc-name {
      max-width: 27rem;
      max-height: 4rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    address {
      font-size: 1.4rem;
      font-family: "Helvetica Neue";
      line-height: 24px;
      color: $color-text-gray;
      font-style: normal;
      display: inline-block;
      margin-right: 10px;
    }
    .el-icon-copy-document {
      color: $color-text-gray;
      font-size: 1.4rem;
    }
    .el-icon-copy-document:hover {
      color: $color-text-secondary;
      cursor: pointer;
    }
    .self-introduction {
      font-size: 1.4rem;
      width: 300px;
    }
  }
  .list-container {
    position: absolute;
    margin-top: 240px;
    width: 100%;
    background: #fffae9;
    .close-event {
      position: absolute;
      border: 1px solid $border-color;
      color: $border-color;
      background: $color-bg;
      right: 30px;
      border-radius: 15px;
      font-size: 2rem;
      cursor: pointer;
    }
    .close-event:hover {
      background: $color-orange;
      color: $color-primary;
      border: 1px solid $color-primary;
    }
    .list-header {
      text-align: center;
      width: 100%;
      font-size: 1.6rem;
      .el-badge {
        cursor: pointer;
      }
    }
    .list-content {
      .event-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        padding-inline-start: 0px;
      }
      .badge-point-wrap {
        display: inline-flex;
        align-items: center;
        height: 36px;
      }
      .badge-point {
        height: 10px;
        width: 10px;
        background-color: #f84848;
        border-radius: 5px;
        color: #fff;
        display: inline-block;
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .event-pagination {
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 40px;
    margin-left: 20px;
    margin-top: 50px;
  }
}
</style>

<style lang="scss">
@import "@/style/var.scss";
.user-description .el-form-item__label {
  line-height: 40px !important;
  font-size: 1.4rem;
}
.user-description .el-select {
  width: 100px;
  input {
    color: transparent;
  }
}
.user-description .selected-avatar {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 20px;
  top: 5px;
  z-index: 1;
}
.avatar-type {
  .el-select-dropdown__item {
    height: 100%;
    padding: 10px 20px;
  }
  .select-avatar {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    opacity: 1;
    float: left;
  }
}
.list-header .el-badge {
  padding-bottom: 2px;
}
</style>
